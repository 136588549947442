<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-if="$route.name == 'OrgRoleList'"
              :to="{
                name: 'SystemSettingsHome',
              }"
            >全系統管理</b-breadcrumb-item>
            <b-breadcrumb-item
              v-if="$route.name == 'OrgRoleList'"
              :to="{
                name: 'OrganizationList',
              }"
            >組織管理</b-breadcrumb-item>

            <b-breadcrumb-item active>角色管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 font-weight-bold">{{ organizationName }}角色列表</h4>
        <div class="col-12 col-xl-6 d-flex align-items-start align-items-xl-center mb-2">
          <b-button
            v-if="checkPermission([consts.ROLE_MODIFY])"
            class="flex-shrink-0 mb-2 mr-2"
            variant="primary"
            :to="getCreateLink()"
          >
            <i class="fa fa-plus"></i>新增角色
          </b-button>

          <b-input-group>
            <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
            <b-input-group-append>
              <b-button @click="getRoles"><i class="fa fa-search"></i ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="roles"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    variant="success"
                    :to="getPermissionEditLink(data)"
                  >
                    編輯權限
                  </b-button>
                  <b-button
                    variant="info"
                    :to="getStaffLink(data)"
                  >
                    相關帳號
                  </b-button>
                  <b-button
                    variant="primary"
                    :to="getShowLink(data)"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.ROLE_MODIFY])"
                    variant="inverse-warning"
                    :to="getEditLink(data)"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.ROLE_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteRole(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="getRoles"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import roleApi from "@/apis/role";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'

export default {
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      roles: [],
      keyword: null,
      fields: [
        {
          key: "name",
          label: "角色名稱",
        },
        {
          key: "code",
          label: "角色代碼",
        },
        {
          key: "level",
          label: "Level",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
      organizationName: '',
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.$route.params.orgId || this.organization.id;
    },
  },
  watch: {
    currentPage() {
      this.getRoles();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getRoles();
    },
    getCreateLink() {
      if (this.$route.name == 'OrgRoleList') {
        return { name: 'OrgRoleCreate', params: { orgId: this.orgId } }
      }
      return { name: 'RoleCreate' }
    },
    getPermissionEditLink(data) {
      if (this.$route.name == 'OrgRoleList') {
        return { name: 'OrgRolePermissionEdit', params: { orgId: this.orgId, role_id: data.item.id } }
      }
      return { name: 'RolePermissionEdit', params: { role_id: data.item.id } }
    },
    getStaffLink(data) {
      if (this.$route.name == 'OrgRoleList') {
        return { name: 'OrgRoleStaffList', params: { orgId: this.orgId, role_id: data.item.id } }
      }
      return { name: 'RoleStaffList', params: { role_id: data.item.id } }
    },
    getShowLink(data) {
      if (this.$route.name == 'OrgRoleList') {
        return { name: 'OrgRoleShow', params: { orgId: this.orgId, role_id: data.item.id } }
      }
      return { name: 'RoleShow', params: { role_id: data.item.id } }
    },
    getEditLink(data) {
      if (this.$route.name == 'OrgRoleList') {
        return { name: 'OrgRoleEdit', params: { orgId: this.orgId, role_id: data.item.id } }
      }
      return { name: 'RoleEdit', params: { role_id: data.item.id } }
    },
    async getRoles(page) {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: page || this.currentPage,
          keyword: this.keyword,
        };
        const { data } = await roleApi.getRoles(this.orgId, params);
        this.roles = data.data;
        this.totalRows = data.meta.total;
        this.organizationName = data.meta.organization_name;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得角色資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteRole(role) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>角色名稱：${role.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              let response = await roleApi.removeRole(this.orgId, role);
              if (response.status === 200) {
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功刪除 " + role.name,
                });
                this.getRoles();
              }
            } catch (error) {
              let message = error.response.data.message || '刪除失敗';
              this.$swal.fire({
                title: "新增失敗",
                type: "error",
                text: message,
              });
            }
          }
        });
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
